import React, { useState, useEffect } from "react";
import { getBapb } from "../../config/api/apiServices";

import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Filter,
  Page,
  Toolbar,
  PdfExport,
  ExcelExport,
  Grid,
  SelectionSettingsModel,
  Search,
} from "@syncfusion/ej2-react-grids";

const GridTableBapb = (props) => {
  console.log(props);
  const filterOption: FilterSettingModel = {
    ignoreAccent: true,
    type: "Menu",
  };

  let grid: Grid | null;
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id.includes("pdfexport")) {
        grid.pdfExport({ fileName: "pemakaian kapal.pdf" });
      } else if (args.item.id.includes("excelexport")) {
        grid.excelExport({ fileName: "pemakaian kapal.xlsx" });
      }
    }
  };

  const selectionOptions: SelectionSettingsModel = {
    mode: "Row",
  };

  // let navigate = useNavigate();

  // const rowSelected = () => {
  //   if (grid) {
  //     const selectedRowIndexes: number[] = grid.getSelectedRowIndexes();
  //     console.log(selectedRowIndexes);
  //     // navigate(`/Kontainer/${selectedRowIndexes}`);
  //   }
  // };

  const doubleClick = () => {};

  const [data, setData] = useState();
  useEffect(() => {
    getBapb(props.nokapal, props.nokont, props.nobapb).then((data) => {
      setData(data);
    });
  }, [props.nokapal, props.nokont, props.nobapb]);

  return (
    <div className="tinggi-table">
      <GridComponent
        height="100%"
        width="100%"
        dataSource={data}
        ref={(g) => (grid = g)}
        allowFiltering={true}
        filterSettings={filterOption}
        allowPaging={true}
        toolbar={["PdfExport", "ExcelExport", "Search"]}
        allowPdfExport={true}
        allowExcelExport={true}
        toolbarClick={toolbarClick}
        selectionSettings={selectionOptions}
        // rowSelected={rowSelected}
        recordDoubleClick={doubleClick}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="ID"
            // textAlign="center"
            width="100"
          />

          {/* <ColumnDirective
            field="no_kapal"
            headerText="NO. KAPAL"
            // textAlign="center"
            width="140"
          />
          <ColumnDirective
            field="no_kont"
            headerText="NO. KONTAINER"
            // textAlign="center"
            // width="100"
          /> */}
          <ColumnDirective
            field="no_bapb"
            headerText="NP. BAPB"
            // textAlign="center"
            width="150"
          />
          <ColumnDirective
            field="pengirim"
            headerText="PENGIRIM"
            textAlign="center"
            width="150"
          />
          <ColumnDirective
            field="nm_brg"
            headerText="ITEMS"
            textAlign="center"
            width="200"
          />
          <ColumnDirective
            field="ket"
            headerText="KETERANGAN"
            textAlign="center"
            width="150"
          />
          <ColumnDirective
            field="qty"
            headerText="Qty"
            textAlign="center"
            width="100"
          />
          <ColumnDirective
            field="satuan"
            headerText="SATUAN"
            textAlign="center"
            width="100"
          />
          <ColumnDirective
            field="p"
            headerText="P"
            textAlign="right"
            width="100"
          />
          <ColumnDirective
            field="l"
            headerText="L"
            textAlign="right"
            width="100"
          />
          <ColumnDirective
            field="t"
            headerText="T"
            textAlign="right"
            width="100"
          />
          <ColumnDirective
            field="m"
            headerText="M"
            textAlign="right"
            width="100"
          />
        </ColumnsDirective>
        <Inject
          services={[Filter, Page, Toolbar, PdfExport, ExcelExport, Search]}
        />
      </GridComponent>
    </div>
  );
};

export default GridTableBapb;
