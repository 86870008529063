import React from "react";
import "./style.css";

import GridTableKapal from "../../../components/GridTableKapal";

import { Collapse } from "antd";

const { Panel } = Collapse;

const Kapal = () => {
  return (
    <div className="content-height">
      <Collapse defaultActiveKey={["1"]} className="collapse-height">
        <Panel header="DATA KAPAL" key="1">
          <GridTableKapal />
        </Panel>
      </Collapse>
    </div>
  );
};

export default Kapal;
