import React from "react";
import Home from "../../containers/pages/Home";
import Kapal from "../../containers/pages/Kapal";
import Kontainer from "../../containers/pages/Kontainer";
import Muatan from "../../containers/pages/Muatan";
import Bapb from "../../containers/pages/Bapb";
import Bapbm from "../../containers/pages/Bapbm";

const pageRouter = () => {
  return [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "/kapal",
      component: <Kapal />,
    },
    {
      path: "/kontainer/:nokapal",
      component: <Kontainer />,
    },
    {
      path: "/muatan/:nokapal/:nokont",
      component: <Muatan />,
    },
    {
      path: "/bapb/:nokapal/:nokont/:nobapb",
      component: <Bapb />,
    },
    {
      path: "/bapbm/:nokapal/:nokont/:nobapb",
      component: <Bapbm />,
    },
  ];
};

export default pageRouter();
