import { Routes, Route, Link } from "react-router-dom";

import "./App.css";

import pageRouter from "./config/router/pageRouter";

import { Layout, Menu } from "antd";
import { UserOutlined, VideoCameraOutlined } from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

const App = () => {
  return (
    <Layout>
      <Sider
        // style={{
        //   // overflow: "auto",
        //   height: "100vh",
        //   // position: "fixed",
        //   left: 0,
        //   top: 0,
        //   bottom: 0,
        // }}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["4"]}>
          <Menu.Item key="1" icon={<UserOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<VideoCameraOutlined />}>
            <Link to="/kapal">Pemakaian Kapal</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        {/* <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0 }}
        /> */}
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Routes>
              {pageRouter.map((data, i) => {
                return (
                  <Route
                    key={i}
                    path={data.path}
                    exect
                    element={data.component}
                  />
                );
              })}
            </Routes>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};
export default App;
