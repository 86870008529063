import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getKapal } from "../../config/api/apiServices";
import "./styleKapal.css";

import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Filter,
  Page,
  Toolbar,
  PdfExport,
  ExcelExport,
  Grid,
  SelectionSettingsModel,
  Search,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";

const GridTabelKapal = () => {
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: "Menu",
  };

  let grid: Grid | null;
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id.includes("pdfexport")) {
        grid.pdfExport({ fileName: "pemakaian kapal.pdf" });
      } else if (args.item.id.includes("excelexport")) {
        grid.excelExport({ fileName: "pemakaian kapal.xlsx" });
      } else if (args.item.id === "addButton") {
        alert("Add toolbar click...");
      } else if (args.item.id === "editButton") {
        alert("edit toolbar click...");
      } else if (args.item.id === "deleteButton") {
        alert("delete toolbar click...");
      }
    }
  };

  const selectionOptions: SelectionSettingsModel = {
    mode: "Row",
  };

  // let navigate = useNavigate();
  // const rowSelected = () => {
  //   if (grid) {
  //     // let a = grid.getSelectedRecords();
  //     // a.map((x) => {
  //     //   return localStorage.setItem("nokapal", x.no_kapal);
  //     // });
  //     // const selectedRowIndexes: number[] = grid.getSelectedRowIndexes();
  //     // console.log(selectedRowIndexes);
  //     // return localStorage.setItem("idkapal", selectedRowIndexes);
  //     // navigate(`/Kontainer/${selectedRowIndexes}`);
  //     // console.log(grid.getSelectedRecords()[0].no_kapal);
  //   }
  // };

  let navigate = useNavigate();

  let nokapal = "";
  const rowSelected = () => {
    if (grid) {
      let a = grid.getSelectedRecords()[0].no_kapal;
      console.log(a);
      nokapal = a;
    }
  };

  //api services
  const [data, setData] = useState();
  useEffect(() => {
    getKapal().then((data) => {
      setData(data);
    });
  }, []);

  const toolbarku = [
    {
      text: "Add",
      tooltipText: "Tambah Kapal",
      prefixIcon: "e-add",
      id: "addButton",
    },
    {
      text: "Edit",
      tooltipText: "Ubah Kapal",
      prefixIcon: "e-edit",
      id: "editButton",
    },
    {
      text: "Delete",
      tooltipText: "Hapus Kapal",
      prefixIcon: "e-delete",
      id: "deleteButton",
    },
    "PdfExport",
    "ExcelExport",
    "Search",
  ];

  const commands = [
    {
      // type: "Edit",
      buttonOption: {
        iconCss: " e-icons e-play",
        cssClass: "e-flat",
        click: () => {
          // alert(nokontselected);
          navigate(`/kontainer/${nokapal}`);
        },
      },
    },
  ];

  return (
    <div className="tinggi-table">
      <GridComponent
        height="100%"
        width="100%"
        dataSource={data}
        ref={(g) => (grid = g)}
        allowFiltering={true}
        filterSettings={filterOption}
        allowPaging={true}
        toolbar={toolbarku}
        allowPdfExport={true}
        allowExcelExport={true}
        toolbarClick={toolbarClick}
        selectionSettings={selectionOptions}
        rowSelected={rowSelected}
        // recordDoubleClick={rowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            // field="id"
            // headerText="Test"
            // textAlign="center"
            width="50"
            commands={commands}
          />
          <ColumnDirective
            field="id"
            headerText="ID"
            // textAlign="center"
            width="100"
          />

          <ColumnDirective
            field="tgl_etd"
            headerText="Tanggal ETD"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="nm_kapal"
            headerText="Nama Kapal"
            // textAlign="center"
            width="300"
          />

          <ColumnDirective
            field="no_kapal"
            headerText="No. Kapal"
            // textAlign="center"
            width="120"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Filter,
            Page,
            Toolbar,
            PdfExport,
            ExcelExport,
            Search,
            CommandColumn,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default GridTabelKapal;
