import React from "react";
import { useParams } from "react-router-dom";
import "./style.css";

import GridTableContainer from "../../../components/GridTableContainer";
import { Collapse } from "antd";

const { Panel } = Collapse;

const Kontainer = () => {
  let { nokapal } = useParams();
  return (
    <div className="content-height">
      <h3>NO. KAPAL {nokapal}</h3>
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="DATA CONTAINER" key="1">
          <GridTableContainer nokapal={nokapal} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default Kontainer;
