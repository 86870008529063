import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getContainer } from "../../config/api/apiServices";
import "./styleContainer.css";

import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Filter,
  Page,
  Toolbar,
  PdfExport,
  ExcelExport,
  Grid,
  SelectionSettingsModel,
  Search,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";

const GridTabelContainer = (props) => {
  // console.log(props);
  const filterOption: FilterSettingModel = {
    ignoreAccent: true,
    type: "Menu",
  };

  let grid: Grid | null;
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id.includes("pdfexport")) {
        grid.pdfExport({ fileName: "pemakaian kapal.pdf" });
      } else if (args.item.id.includes("excelexport")) {
        grid.excelExport({ fileName: "pemakaian kapal.xlsx" });
      }
    }
  };

  const selectionOptions: SelectionSettingsModel = {
    mode: "Row",
  };

  // let navigate = useNavigate();

  // const rowSelected = () => {
  //   if (grid) {
  //     const selectedRowIndexes: number[] = grid.getSelectedRowIndexes();
  //     console.log(selectedRowIndexes);
  //     // navigate(`/Kontainer/${selectedRowIndexes}`);
  //   }
  // };

  let navigate = useNavigate();
  let nokapal = "";
  let nokont = "";
  const rowSelected = () => {
    if (grid) {
      let a = grid.getSelectedRecords()[0].no_kapal;
      let b = grid.getSelectedRecords()[0].no_kont;
      nokapal = a;
      nokont = b;
    }
  };

  const [data, setData] = useState();
  useEffect(() => {
    return getContainer(props.nokapal).then((data) => {
      setData(data);
    });
  }, [props.nokapal]);

  const commands = [
    {
      // type: "Edit",
      buttonOption: {
        iconCss: " e-icons e-play",
        cssClass: "e-flat",
        click: () => {
          // alert(nokontselected);
          navigate(`/muatan/${nokapal}/${nokont}`);
        },
      },
    },
  ];

  return (
    <div className="tinggi-table">
      <GridComponent
        height="100%"
        width="100%"
        dataSource={data}
        ref={(g) => (grid = g)}
        allowFiltering={true}
        filterSettings={filterOption}
        allowPaging={true}
        toolbar={["PdfExport", "ExcelExport", "Search"]}
        allowPdfExport={true}
        allowExcelExport={true}
        toolbarClick={toolbarClick}
        selectionSettings={selectionOptions}
        rowSelected={rowSelected}
        // recordDoubleClick={doubleClick}
      >
        <ColumnsDirective>
          <ColumnDirective
            // field="id"
            // headerText="ID"
            // textAlign="center"
            commands={commands}
            width="50"
          />
          <ColumnDirective
            field="id"
            headerText="ID"
            // textAlign="center"
            width="100"
          />

          {/* <ColumnDirective
            field="no_kapal"
            headerText="No. Kapal"
            // textAlign="center"
            width="140"
          /> */}
          <ColumnDirective
            field="no_kont"
            headerText="No. Container"
            // textAlign="center"
            width="150"
          />
          <ColumnDirective
            field="jns_kont"
            headerText="Jenis Container"
            textAlign="center"
            width="170"
          />
          <ColumnDirective
            field="staffing"
            headerText="Staffing"
            textAlign="center"
            width="120"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Filter,
            Page,
            Toolbar,
            PdfExport,
            ExcelExport,
            Search,
            CommandColumn,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default GridTabelContainer;
