const baseUrl = "https://test.gg-ssp.com/api";

//api kapal
//get
exports.getKapal = () => {
  return fetch(baseUrl + "/kapal").then((res) => res.json());
};

//api kontainer
exports.getContainer = (nokapal) => {
  return fetch(baseUrl + "/kontainer/" + nokapal).then((res) => res.json());
};

//api muatan
exports.getMuatan = (nokapal, nokont) => {
  return fetch(baseUrl + "/muatan/" + nokapal + "/" + nokont).then((res) =>
    res.json()
  );
};

//api bapb
exports.getBapb = (nokapal, nokont, nobapb) => {
  return fetch(baseUrl + "/bapb/" + nokapal + "/" + nokont + "/" + nobapb).then(
    (res) => res.json()
  );
};
