import React from "react";
import { useParams } from "react-router-dom";
import "./style.css";

import GridTableBapb from "../../../components/GridTableBapb";
import { Collapse } from "antd";

const { Panel } = Collapse;

const Bapb = () => {
  let { nokapal, nokont, nobapb } = useParams();
  return (
    <div>
      <h3>
        NO. KAPAL {nokapal}, NO. KONT {nokont}, NO. BAPB {nobapb}
      </h3>
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="DATA BAPB" key="1" className="content-height">
          <GridTableBapb nokapal={nokapal} nokont={nokont} nobapb={nobapb} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default Bapb;
